import type { Result } from '@cfgtech/ui'
import { ReturnsPeriodicityEnum } from '~~/stores/issues/types'
import { roundToNearest, withTax } from '~~/helpers/functions'
import { BOND_AMOUNT } from '~/constants/common'
import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'

const DAYS_PER_YEAR = 360
const DAYS_PER_MONTH = 30

const SMALL_NOMINAL_VALUE = 100000
const SMALL_INVESTMENT_COUNT = 1000000

export function useCalculator(bondsCount = BOND_AMOUNT, issue: Ref<IssueDetails> = ref({} as IssueDetails)) {
  const { t } = useI18n()
  const investmentCount = ref(issue.value.nominalValue ? issue.value.nominalValue * bondsCount : 0)

  const minInvestment = computed(() => issue.value.nominalValue ?? 0)
  const maxInvestment = computed(() => Number(issue.value.nominalValue) >= SMALL_NOMINAL_VALUE ? issue.value.totalAmount || SMALL_INVESTMENT_COUNT : SMALL_INVESTMENT_COUNT)

  /**
   * Days count to issue maturity date in 30E/360E format
   */
  function getDaysToMaturity(maturityDate: Date): number {
    const now = new Date()

    const dayTo = maturityDate.getDate() === 31 ? 30 : maturityDate.getDate()
    const dayFrom = now.getDate() === 31 ? 30 : now.getDate()

    return (
      DAYS_PER_YEAR * (maturityDate.getFullYear() - now.getFullYear())
      + DAYS_PER_MONTH * (maturityDate.getMonth() - now.getMonth())
      + (dayTo - dayFrom)
    )
  }

  /**
   * Calculates periodic return (monthly, quarterly, half-yearly, yearly)
   */
  function calculatePeriodic(): number {
    const annualInterestRate = issue.value.interestRate?.annualInterestRate

    if (!annualInterestRate) {
      return 0
    }

    // Round to nearest (max, or min) to prevent nonsense values
    const normalizedInvestmentCount = roundToNearest(investmentCount.value, { min: minInvestment.value, max: maxInvestment.value })

    switch (issue.value.returnsPeriodicity) {
      case ReturnsPeriodicityEnum.Monthly:
        return withTax((normalizedInvestmentCount * (annualInterestRate / 100) / 12)) // 12 months per year

      case ReturnsPeriodicityEnum.Quarterly:
        return withTax((normalizedInvestmentCount * (annualInterestRate / 100) / 4)) // 4 quarters per year

      case ReturnsPeriodicityEnum.HalfYearly:
        return withTax((normalizedInvestmentCount * (annualInterestRate / 100) / 2)) // 2 half years per year

      case ReturnsPeriodicityEnum.Yearly:
        return withTax((normalizedInvestmentCount * (annualInterestRate / 100))) // 1 year

      default: return 0 // Unknown or unsupported periodicity
    }
  }

  /**
   * Calculates whole period return
   */
  function calculateWholePeriod(): number {
    if (!issue.value.interestRate?.annualInterestRate || !issue.value.maturityDate) {
      return 0
    }

    const dailyInterestRate = issue.value.interestRate.annualInterestRate / DAYS_PER_YEAR / 100

    const normalizedInvestmentCount = roundToNearest(investmentCount.value, {
      min: minInvestment.value,
      max: maxInvestment.value,
    })

    return withTax((normalizedInvestmentCount * dailyInterestRate) * getDaysToMaturity(new Date(issue.value.maturityDate)))
  }

  const result = computed<Result>(() => {
    return {
      periodic: `${useMoneyFormat(calculatePeriodic(), true)} ${t(`common.currencies.${issue.value.currency}`)}`,
      wholePeriod: `${useMoneyFormat(calculateWholePeriod(), true)} ${t(`common.currencies.${issue.value.currency}`)}`,
    }
  })

  const mainParams = computed(() => ({
    maturityDate: {
      label: t('issue.maturityDate.title'),
      value: issue.value.maturityDate ? new Date(issue.value.maturityDate).toLocaleDateString() : '-',
      tooltip: t('issue.maturityDate.hint'),
    },
    interestRate: {
      label: t('bondList.table.head.interestRate.title'),
      value: issue.value?.interestRate?.annualInterestRate ? `${issue.value.interestRate.annualInterestRate}%` : '-',
      tooltip: t('bondList.table.head.interestRate.hint'),
    },
    issueNominalValue: {
      label: t('issue.nominalValueItems.title', { count: 1 }),
      value: issue.value.nominalValue ? issue.value.nominalValue.toLocaleString() : '-',
      tooltip: t('issue.nominalValue.hint'),
    },
  }))

  return {
    investmentCount,
    minInvestment,
    maxInvestment,
    calculatePeriodic,
    calculateWholePeriod,
    result,
    issue,
    mainParams,
  }
}
