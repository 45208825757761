import type { Issue } from '~/api/types'

export function useIssueOrderAvailability() {
  const issue = ref({} as Issue)
  const isIssueAvailable = () => issue && Number(issue.value.availableCount) > 0 && issue.value.isAvailable && Number(issue.value.top) > 0

  const orderURL = computed(() => isIssueAvailable() && issue.value.showBuyButton !== false ? issue?.value.jamesOrderFormURL : '')

  const orderAvailabilityTranslateKey = computed(() => {
    if (isIssueAvailable()) {
      return ''
    }

    if ((issue?.value.availableCount === 0 || Number(issue?.value.availableCount) < 0) || issue?.value.isAvailable === false) {
      return 'issue.heroSection.calculator.showMoreIssue.unavailableToBuy'
    }

    return 'issue.heroSection.calculator.showMoreIssue.title'
  })

  return {
    orderURL,
    orderAvailabilityTranslateKey,
    issue,
  }
}
