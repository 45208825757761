export function useMoneyFormat(
  value: number | bigint,
  withoutDecimals = false,
): string {
  const number = new Intl.NumberFormat('cs', {
    maximumFractionDigits: withoutDecimals ? 0 : 2,
    minimumFractionDigits: withoutDecimals ? 0 : 2,
  }).format(value)

  return number === 'NaN' ? '0.00' : number
}
