<script setup lang="ts">
defineProps<{
  asterisk?: boolean
}>()

const { isModalTaxOpened } = useTaxModal()
</script>

<template>
  <span
    class="text-brand hover:cursor-pointer"
    @click="isModalTaxOpened = true"
  > {{ asterisk ? '&#42;' : '' }}{{ $t('tax.link') }} </span>
</template>
